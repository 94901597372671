import React from "react";
import moment from "moment";
import { useSelector } from 'react-redux'
import { Link, useParams } from "react-router-dom";

const UserProfile = ({ isLoading, isError, userProfile, userName, joinedOn }) => {

     const { searchedUserId } = useParams();
     const logedInPersonId = useSelector((state) => state.LoginSlice.loggedUserId);

     return (
          <>
               <div className="flex items-ce justify-between text-black ">
                    <h3 className="font-semibold text-3xl mb-2"> Intro </h3>
               </div>

               {isError && (<div className="text-lg font-light text-gray-500 text-center">Someting Went Wrong. . . </div>)}

               {!isLoading ? (
                    <div className="w-full md:w-72 lg:w-96 lg:shadow-none m-1 space-y-10 bg-white">
                         <div className="box py-2">
                              <ul className="text-gray-700 space-y-4 text-sm">
                                   {userName && (
                                        <li className="flex items-center gap-3">
                                             <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                  <path strokeLinecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
                                             </svg>
                                             <div>
                                                  User Name{" "} <span className="font-semibold text-black">{userName}</span>
                                             </div>
                                        </li>
                                   )}
                                   {joinedOn && (
                                        <li className="flex items-center gap-3">
                                             <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                  <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                             </svg>
                                             <div>
                                                  Joined On{" "} <span className="font-semibold text-black">{moment(joinedOn).format("MMMM DD, YYYY")}</span>
                                             </div>
                                        </li>
                                   )}

                                   {userProfile === null ?
                                        <>
                                             {logedInPersonId === searchedUserId && (

                                                  <div className="cursor-pointer w-full text-center h-fit px-4 py-2 bg-blue-600 text-white font-semibold active:opacity-75 rounded-md mx-auto">
                                                       <Link style={{ textDecoration: "none", color: "white" }} to="/update-profile">Complete Your Profile</Link>
                                                  </div>
                                             )}
                                        </>
                                        :
                                        <>
                                             {userProfile &&
                                                  (userProfile?.city ||
                                                       userProfile?.state ||
                                                       userProfile?.country) && (
                                                       <li className="flex items-center gap-3 text-base">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                                 <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                                                 <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                                                            </svg>
                                                            <div>
                                                                 Live In{" "} <span className="font-semibold text-black">{`${userProfile.city} ${userProfile.state} ${userProfile.country}`}</span>
                                                            </div>
                                                       </li>
                                                  )}

                                             {userProfile?.phoneNumber && (
                                                  <li className="flex items-center gap-3 text-base">
                                                       <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                                                       </svg>
                                                       <div>
                                                            Phone Number{" "} <span className="font-semibold text-black">{userProfile.phoneNumber}</span>
                                                       </div>
                                                  </li>
                                             )}

                                             {userProfile?.dateOfBirth && (
                                                  <li className="flex items-center gap-3 text-base">
                                                       <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 8.25v-1.5m0 1.5c-1.355 0-2.697.056-4.024.166C6.845 8.51 6 9.473 6 10.608v2.513m6-4.87c1.355 0 2.697.055 4.024.165C17.155 8.51 18 9.473 18 10.608v2.513m-3-4.87v-1.5m-6 1.5v-1.5m12 9.75l-1.5.75a3.354 3.354 0 01-3 0 3.354 3.354 0 00-3 0 3.354 3.354 0 01-3 0 3.354 3.354 0 00-3 0 3.354 3.354 0 01-3 0L3 16.5m15-3.38a48.474 48.474 0 00-6-.37c-2.032 0-4.034.125-6 .37m12 0c.39.049.777.102 1.163.16 1.07.16 1.837 1.094 1.837 2.175v5.17c0 .62-.504 1.124-1.125 1.124H4.125A1.125 1.125 0 013 20.625v-5.17c0-1.08.768-2.014 1.837-2.174A47.78 47.78 0 016 13.12M12.265 3.11a.375.375 0 11-.53 0L12 2.845l.265.265zm-3 0a.375.375 0 11-.53 0L9 2.845l.265.265zm6 0a.375.375 0 11-.53 0L15 2.845l.265.265z" />
                                                       </svg>
                                                       <div>
                                                            Date of birth{" "} <span className="font-semibold text-black">{moment(userProfile.dateOfBirth).format("MMMM DD, YYYY")}</span>
                                                       </div>
                                                  </li>
                                             )}

                                             {userProfile?.studiedAt && (
                                                  <li className="flex items-center gap-3 text-base">
                                                       <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
                                                       </svg>
                                                       <div>
                                                            Studied at{" "}<span className="font-semibold text-black">{userProfile.studiedAt}</span>
                                                       </div>
                                                  </li>
                                             )}

                                             {userProfile?.youAre && (
                                                  <li className="flex items-center gap-3 text-base">
                                                       <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
                                                       </svg>
                                                       <div>
                                                            Currently{" "}<span className="font-semibold text-black">{userProfile.youAre}</span>
                                                       </div>
                                                  </li>
                                             )}
                                             {/* eslint-disable-next-line */}
                                             {userName === "@shubham" || userName === "shubham09anand" && (
                                                  <div>
                                                       <div className="font-semibold text-black text-lg mb-2">Other Social Platforms</div>
                                                       <div className="flex space-x-5">
                                                            <a href="https://www.instagram.com/shubham09anand/" target='_'>
                                                                 <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" strokeWidth="2" className="bi bi-instagram size-5 mr-2" viewBox="0 0 16 16">
                                                                      <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                                                                 </svg>
                                                            </a>
                                                            <a href="https://www.linkedin.com/in/subham09anand/" target='_'>
                                                                 <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" strokeWidth="2" className="bi bi-linkedin size-5 mr-2" viewBox="0 0 16 16">
                                                                      <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                                                                 </svg>
                                                            </a>
                                                            <a href="https://github.com/shubham09anand" target='_'>
                                                                 <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" strokeWidth="2" className="bi bi-github size-5 mr-2" viewBox="0 0 16 16">
                                                                      <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27s1.36.09 2 .27c1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.01 8.01 0 0 0 16 8c0-4.42-3.58-8-8-8" />
                                                                 </svg>
                                                            </a>
                                                            <a href="https://shubham09anand.in/" target='_'>
                                                                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5 mr-2">
                                                                      <path strokeLinecap="round" strokeLinejoin="round" d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244" />
                                                                 </svg>
                                                            </a>
                                                       </div>
                                                  </div>

                                             )}

                                             {userProfile && userName && joinedOn && (
                                                  <>
                                                       {searchedUserId === logedInPersonId && (
                                                            <Link style={{ textDecoration: "none", color: "white" }} to="/update-profile" className="cursor-pointer">
                                                                 <div className="w-full text-center h-fit px-4 py-2 bg-[#7090e3] text-white font-semibold active:opacity-75 rounded-md mx-auto mt-4">Edit Profile</div>
                                                            </Link>
                                                       )}
                                                  </>
                                             )}
                                        </>
                                   }
                              </ul>
                         </div>
                    </div>
               ) :
                    (
                         <div className="w-full md:w-72 lg:w-96 space-y-2">
                              {[...Array(8)].map((_, index) => (
                                   <div key={index} className="flex space-x-3">
                                        <div className="h-3 w-2/5 bg-gray-400/50"></div>
                                        <div className="h-3 w-3/5 bg-gray-400/50"></div>
                                   </div>
                              ))}
                         </div>
                    )
               }
          </>
     );
};
export default UserProfile;